/* @font-face {
  font-family: 'Karla-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/karla/karla-v23-latin-regular.eot);
  src: local('Karla'),
    url(./fonts/karla/karla-v23-latin-regular.eot?#iefix) format('embedded-opentype'),
    url(./fonts/karla/karla-v23-latin-regular.woff2) format('woff2'),
    url(./fonts/karla/karla-v23-latin-regular.woff) format('woff'),
    url(./fonts/karla/karla-v23-latin-regular.ttf) format('truetype'),
    url(./fonts/karla/karla-v23-latin-regular.svg#Karla-Regular) format('svg');
} */

/* @font-face {
  font-family: 'Karla-Medium';
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/karla/karla-v23-latin-500.eot);
  src: local('Karla'),
    url(./fonts/karla/karla-v23-latin-500.eot?#iefix) format('embedded-opentype'),
    url(./fonts/karla/karla-v23-latin-500.woff2) format('woff2'),
    url(./fonts/karla/karla-v23-latin-500.woff) format('woff'),
    url(./fonts/karla/karla-v23-latin-500.ttf) format('truetype'),
    url(./fonts/karla/karla-v23-latin-500.svg#Karla-Medium) format('svg');
} */

@font-face {
  font-family: 'SplineSansMono-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('SplineSansMono'), 
    url(./fonts/spline/SplineSansMono-Regular.eot?#iefix) format('embedded-opentype'),
    url(./fonts/spline/SplineSansMono-Regular.woff2) format('woff2'),
    url(./fonts/spline/SplineSansMono-Regular.woff) format('woff'),
    url(./fonts/spline/SplineSansMono-Regular.ttf) format('truetype'),
    url(./fonts/spline/SplineSansMono-Regular.svg#SplineSansMono-Regular) format('svg');
}

@font-face {
  font-family: 'Karla-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/familjen.ttf);
}

@font-face {
  font-family: 'Karla-Medium';
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/familjen.ttf);
}

@font-face {
  font-family: 'Familjen';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/familjen.ttf);
}